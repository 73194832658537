export default function sectionBlocks() {
  if (typeof window !== "undefined" && window.document) {
    if (window.innerWidth >= 841) {
      window.document.body.classList.remove("Pacnav--is-mobile")
    } else {
      window.document.body.classList.add("Pacnav--is-mobile")
    }

    const blocks = document.querySelectorAll(".Block:not(.is-active)")

    window.addEventListener("scroll", isActive)

    function isActive(e) {
      for (let block of blocks) {
        let eleTop = block.getBoundingClientRect().top

        if (eleTop < window.innerHeight * 0.75) {
          block.classList.add("is-active")
        }
      }
    }
    isActive(null)

    const blockAnchors = [
      ...document.querySelectorAll(".Block.show-on-scroll, .TabsBlock-label"),
    ].flatMap(block => (block.id ? "#" + block.id : []))
    if (blockAnchors.includes(window.location.hash)) {
      revealOnAnchorScroll()
    }

    window.addEventListener("hashchange", revealOnAnchorScroll)

    function revealOnAnchorScroll() {
      if (blockAnchors.includes(window.location.hash)) {
        const blockId = blockAnchors
          .find(el => el === window.location.hash)
          .replace("#", "")
        const block = document.getElementById(blockId)
        block.classList.add("has-scrolled")

        if (block.classList.contains("ShowMoreBlock")) {
          block.classList.add("is-toggled")
        }
        if (block.classList.contains("TabsBlock-label")) {
          ;[...block.parentElement.children].forEach(b => {
            b.classList.remove("is-active")
          })
          block.classList.add("is-active")
        }

        let scrollOffset = 150
        window.scrollBy({
          top: Math.floor(block.getBoundingClientRect().top - scrollOffset),
          left: 0,
          behavior: "smooth",
        })
      }
    }

    return true
  }
}
