import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import Announcement from "../components/Announcement/Announcement"
import Footer from "../components/Footer/footer"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterMinimal from "../components/Footer/footerMinimal"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import sectionBlocks from "../utilities/sectionBlocks"

const NotFoundPage = ({ data, location }) => {
  if (!data?.wpPage) return <></>

  const { content, headerAndFooter } = data.wpPage

  const setFooter = headerAndFooter?.pageFooterStyle
  const { viewPortWidth, isAnnouncementActive } = React.useContext(StoreContext)

  React.useEffect(() => {
    sectionBlocks()
  }, [])

  return (
    <Layout>
      <Seo pageData={data.wpPage} location={location} />

      <div className="error404 wp-embed-responsive header-default footer-default">
        <div
          className={`Page ${isAnnouncementActive ? "has-announcement" : ""} `}
        >
          <Announcement />
          <Header
            siteTitle={`GoodHire`}
            viewPortWidth={viewPortWidth}
            headerStyle={headerAndFooter.pageHeaderStyle}
            headerPartnerLogo={headerAndFooter.headerPartnerLogo}
          />
          <div className="Page-content">{parse(content)}</div>

          {setFooter === null && <Footer />}

          {setFooter === "default" && <Footer />}

          {setFooter === "landing-page" && <FooterLandingPage />}

          {setFooter === "get-started" && <FooterMinimal />}
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    wpPage(databaseId: { eq: 1761 }) {
      id
      title
      headerAndFooter {
        pageFooterStyle
        pageHeaderStyle
      }
      databaseId
      content
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
  }
`
